import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import NavBar from "../vue/NavBar";

function Layout() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Box sx={{ flexGrow: 1, paddingBottom: "60px" }}> 
        <Outlet />
      </Box>
      <NavBar />
    </Box>
  );
}

export default Layout;
