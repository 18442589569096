import React, { useState } from 'react';
import { Box, Dialog, DialogActions, Container, Divider, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export default function TermsModal({ open, onClose, onAccept }) {
    const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

    const SectionTitle = styled(Typography)(({ theme }) => ({
        margin: theme.spacing(2, 0),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
    }));

    const handleScroll = (event) => {
        const scrollHeight = event.target.scrollHeight;
        const scrollTop = event.target.scrollTop;
        const clientHeight = event.target.clientHeight;
        const tolerance = 5;
        
        
        if (scrollHeight - scrollTop - tolerance <= clientHeight) {
            setHasScrolledToBottom(true);
        } else {
            setHasScrolledToBottom(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogContent>
                <div
                    style={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        padding: '1rem',
                    }}
                    onScroll={handleScroll}
                >
                    <Container maxWidth="md">
                        <Typography variant="h5" sx={{align:"center",fontWeight: "bold",}}>
                            Conditions générales d'utilisation
                        </Typography>
                        <Typography variant="subtitle1" align="center" gutterBottom>
                            SAS - Studio Barber • Siret : 902 291 368 00022
                        </Typography>
                        <Typography variant="subtitle2" align="center" gutterBottom>
                            Adresse : 1 Rue Aubert Dubayet, 38000 Grenoble
                        </Typography>
                        <Typography variant="subtitle2" align="center" gutterBottom>
                            Les présentes conditions générales ont pour objet de définir les modalités d’utilisation et
                            conditions de vente de l'abonnement proposé par le Salon Studio Barber, sis 1 Rue Aubert
                            Dubayet, 38000 Grenoble - Studio Barber, Grenoble, France et, l’Adhérent(e).<br />
                            Les conditions ci-dessus font office de contrat et obligent les parties qui les ont acceptées.
                        </Typography>
                        <Divider sx={{ my: 4 }} />
                        <Box>
                            <SectionTitle variant="h5">1. Objet de la Convention</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Après avoir pris connaissance des prestations proposées par le Salon Studio Barber,
                                l’Adhérent(e), déclare souscrire un Abonnement Salon Studio Barber, nominatif et
                                incessible, lui permettant d’accéder aux prestations visées au titre de l’abonnement avec un
                                accès illimité aux prestations prévues, dans la limite des horaires d’ouverture, dans le cadre
                                du forfait de base comprenant : Coupes de cheveux et taille de la barbe.
                                Les présentes conditions générales de vente et d’utilisation présenté à l’Adhérent(e) lors de la
                                souscription, qui déclare les accepter, constituent le contrat qui forme le cadre exclusif des
                                relations juridiques entre l’Adhérent(e) et le Salon Studio Barber.
                            </Typography>
                        </Box>


                        <Box>
                            <SectionTitle variant="h5">2. Offre d'Abonnement</SectionTitle>
                            <Typography variant="body1" paragraph>
                                L'abonnement est proposé au tarif forfaitaire mensuel de 19,99 € (euros), avec un
                                engagement d'une durée de douze (12) mois. L’abonnement permet à l’Adhérent(e) de
                                prendre rendez-vous de manière illimité afin de bénéficier des prestations visées ci-après,
                                dans la mesure des disponibilités, des horaires d’ouvertures de l’établissement et des
                                contraintes relatives au fonctionnement de ce dernier. Le nombre de rendez-vous pris par
                                l’Adhérent(e) est libre, néanmoins, pour des raisons liées au bon fonctionnement de
                                l’établissement, le Salon Studio Barber peut moduler la prise de rendez-vous afin d’assurer
                                un service qualitatif à tous ses Adhérent(e)s. L’abonnement donne droit uniquement aux
                                prestations visées, toute autre prestation souhaitée par l’Adhérent(e) impliquera le paiement
                                du prix correspondant à celle-ci. Le Salon Studio Barber se réserve le droit de refuser de
                                réaliser une prestation non contenue dans l’offre.
                                L'abonnement comprend les prestations suivantes :
                                - Coupes de cheveux
                                - Taille de la barbe
                            </Typography>
                        </Box>


                        <Box>

                            <SectionTitle variant="h5">3. Conditions d'Utilisation</SectionTitle>
                            <SectionTitle variant="h6">3.1. Durée de l’Abonnement</SectionTitle>
                            <Typography variant="body1" paragraph>
                                L'abonnement est souscrit pour une durée indéterminée avec un engagement de douze (12)
                                mois, à compter de la date de souscription. À l'issue de cette période, l'abonnement sera
                                renouvelé tacitement et soumit aux mêmes conditions, sauf résiliation par l’Adhérent(e) dans
                                les conditions prévues à l'article 8.
                            </Typography>
                        </Box>


                        <Box>
                            <SectionTitle variant="h6">3.2. Accès aux Services</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Afin de pouvoir souscrire un Abonnement Salon Studio Barber, L’Adhérent(e) doit créer un
                                compte en ligne sur le site Internet du Salon. Lors de son enregistrement, L'Adhérent(e) doit
                                préciser une adresse électronique valide et un mot de passe lui permettant ensuite d’accéder à
                                certaines caractéristiques ou fonctions du Site et de les utiliser. L’Adhérent(e) s’engage à ne
                                fournir que des informations véridiques, exactes, à jour et complètes. L’adresse électronique
                                ou identifiant et le mot de passe de l’Adhérent(e) sont personnels et il ne pourra en aucune
                                circonstance autoriser autrui à les utiliser pour accéder et utiliser le site ou l’application.
                                L'Adhérent(e) s’engage à assumer la responsabilité de toutes les activités qui se déroulent sur
                                son compte et convient qu’il ne cédera pas son compte à un tiers. L’Adhérent(e) s’engage à
                                notifier immédiatement s’il a connaissance d’une utilisation non autorisée de son adresse
                                électronique, identifiant ou mot de passe ou d’une raison imposant la modification de son
                                identifiant ou de son mot de passe pour des motifs de sécurité, ou s’il a des raisons de penser
                                qu’il y en a eu ou qu’il pourrait y en avoir. Chaque Adhérent(e) devra impérativement
                                prendre rendez-vous sur l’application et/ou sur le site internet Salon Studio Barber, afin de
                                bénéficier des services compris dans l’abonnement. L'application comporte un code QR
                                permettant d’identifier l’Adhérent(e), la présentation de ce dernier est obligatoire pour
                                pouvoir bénéficier des prestations. Le code QR est nominatif et exclusivement rattaché en la
                                personne de l’Adhérent(e). Son usage est rigoureusement personnel et incessible, il ne peut
                                être utilisé par un tiers. Le Salon Studio Barber se réserve le droit de refuser toute personne
                                ne justifiant pas d’un titre d’abonnement ou bien si le titre ne correspond pas à la personne
                                physique se présentant au moment du rendez-vous. Le Salon Studio Barber se donne le droit
                                de refuser l’Adhérent(e) n’ayant pas pris préalablement un rendez-vous via l’application ou le
                                site internet.
                            </Typography>
                        </Box>


                        <Box>
                            <SectionTitle variant="h6">3.3 - Modalités d’Utilisation</SectionTitle>
                            <Typography variant="body1" paragraph>
                                L’Adhérent(e) pourra accéder aux services prévus au titre de l’abonnement de manière
                                illimitée, sous réserve des horaires d'ouverture du Salon Studio Barber et de la disponibilité
                                des rendez-vous proposés. Les services du Salon Studio Barber seront accessibles de manière
                                hebdomadaire, l’Adhérent(e) pourra prendre rendez-vous chaque semaine. La prise de
                                rendez-vous se fait uniquement via l’application ou le site internet Salon Studio Barber.
                                Dans le cadre de l’abonnement, L'Adhérent(e) s’engage à honorer chaque rendez-vous pris
                                avant de pouvoir en réserver un autre. Dans l’hypothèse où l’Adhérent(e) ne peut se rendre au
                                rendez-vous, ce dernier doit en informer le Salon Studio Barber 48h à l’avance, à défaut le
                                rendez-vous sera considéré comme non honoré.
                                Tout rendez-vous non honoré sans juste motif et non décommandé 48h à l’avance pourra
                                entraîner une restriction dans la prise de rendez-vous.
                                L’Adhérent(e) qui n’honore pas trois (3) rendez-vous consécutifs ne pourra pas prendre de
                                rendez-vous pendant dix (10) jours.
                                L’Adhérent(e) qui n’honore pas cinq (5) rendez-vous consécutifs ne pourra pas prendre de
                                rendez-vous pendant vingt (20) jours.
                            </Typography>
                            <Typography variant="subtitle2" align="center" gutterBottom>
                                L’abonnement donne accès aux services prévus dans l’ensemble des établissements
                                Salon Studio Barber.

                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">4. Obligation de L’Adhérent</SectionTitle>
                            <Typography variant="body1" paragraph>
                                L’Adhérent(e) s’engage à respecter les conditions d’utilisation et de vente visées au titre de la présente convention.
                                <br />
                                L’Adhérent(e) s’engage à prendre uniquement rendez-vous par l’intermédiaire de l’application et/ou du site internet.
                                <br />
                                L’Adhérent(e) s’engage à honorer chaque rendez-vous pris.
                                <br />
                                L’Adhérent(e) s’engage à présenter son titre d’abonnement à chaque rendez-vous. À défaut, il ne pourra bénéficier de son rendez-vous et ce dernier sera considéré comme non honoré.
                                <br />
                                L’Adhérent(e) s’engage à se présenter à l’heure de son rendez-vous.
                                <br />
                                Dans l’hypothèse d’un retard supérieur à cinq (5) minutes, le Salon Studio Barber se réserve le droit de refuser d’effectuer la prestation si celle-ci ne peut être faite dans le temps imparti prévu pour le rendez-vous pris.
                                <br />
                                L’abonnement est exclusivement nominatif, et ne peut être utilisé par une personne autre que l’Adhérent(e). En cas de manquements répétés à cette obligation, le Salon Studio Barber se réserve le droit de suspendre le compte de l’Adhérent(e).
                                <br />
                                L'Adhérent(e) s’engage à respecter le règlement intérieur du Salon Studio Barber, à adopter un comportement raisonnable ne perturbant pas le bon fonctionnement du Salon.
                            </Typography>
                        </Box>


                        <Box>
                            <SectionTitle variant="h5">5.Obligations de Salon Studio Barber</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Le Salon Studio Barber s’engage à assurer et à fournir les services prévus au titre de
                                l’abonnement souscrit par l’Adhérant(e) avec diligence et professionnalisme. Le Salon Studio
                                Barber s’engage à garantir l’accès à ses services, notamment à l’application et au site internet
                                Salon Studio Barber, afin que l'Adhérent(e) puisse prendre rendez-vous. Le Salon Studio
                                Barber ne peut garantir la qualité du réseau internet, ni son accès et ne peut garantir l’absence
                                d’interruption du service propre au réseau Internet, ce qui pourrait entraîner une impossibilité
                                pour l’Adhérent(e) de prendre rendez-vous. Le Salon Studio Barber ne saurait être tenu
                                responsable si le planning hebdomadaire est complet. Il incombe à chaque Adhérent(e) de
                                faire preuve de diligence dans la prise de rendez-vous.
                            </Typography>
                        </Box>{/* Section: Résiliation */}
                        <Box>
                            <SectionTitle variant="h5">6. Modalités de Paiement</SectionTitle>
                            <SectionTitle variant="h6">6.1. Le Paiement
                            </SectionTitle>
                            <Typography variant="body1" paragraph>
                                Le prix de l’abonnement est payable mensuellement. Le montant de l'abonnement mensuel,
                                soit 19,99 € (euros), sera prélevé automatiquement à la date d’anniversaire de la souscription.
                                Le premier paiement s’effectuera, à terme à échoir (paiement par avance), lors de
                                l’abonnement au service du Salon Studio Barber, puis mensuellement, par prélèvement
                                automatique, jusqu’à résiliation de l’abonnement, que la résiliation soit à l’initiative de
                                l’Adhérent(e) ou de celle du Salon Studio Barber. Les garanties accordées par le Salon Studio
                                Barber au titre de la sécurité des transactions sont identiques à celles obtenues respectivement
                                par le Salon Studio Barber de ces éditeurs des systèmes de paiement précités. À défaut
                                d’éléments contraires apportés par L’Adhérent(e), les registres informatisés, conservés dans
                                les systèmes informatiques du Salon Studio Barber et de ses partenaires dans des conditions
                                raisonnables de sécurité, seront considérés comme les preuves des communications, des
                                commandes, des validations et des paiements intervenus entre l’Adhérent(e) et le Salon
                                Studio Barber. L’archivage de ces éléments est effectué sur un support fiable et durable de
                                manière à correspondre à une copie fiable conformément à l’article 1379 du Code civil.
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h6">6.2. Modification des Tarifs</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Le prix de l’abonnement au service du Salon Studio Barber est indiqué, sur l’application et
                                sur le site internet, en euros et toutes taxes comprises. Le Salon Studio Barber se réserve le
                                droit de modifier le prix de chacune des formules d’abonnement. Le Salon Studio Barber
                                informera chaque Adhérent(e) par courrier électronique à l’adresse renseignée par ce dernier
                                lors de son inscription sur l’application Salon Studio Barber (ou modifiée par la suite sur son
                                compte) de toute hausse de tarif au minimum trente (30) jours avant sa prise d’effet. Dans
                                une telle hypothèse, L’Adhérent(e) n’acceptant pas cette hausse de tarif pourra résilier son
                                abonnement dans les conditions indiquées à l’article 8 des présentes conditions générales
                                d’utilisation et de vente. Dans cette hypothèse, la résiliation prendra effet à la prochaine
                                échéance d’abonnement. À défaut de résiliation par l’Adhérent(e), la nouvelle tarification
                                sera dès lors applicable à compter de la prochaine échéance suivant la notification de la
                                modification tarifaire.
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h6">6.3. Prévention des impayés </SectionTitle>
                            <Typography variant="body1" paragraph>
                                En cas de défaut de paiement total ou partiel, de l’une des mensualités, Le compte de
                                l’Adhérent(e) sera suspendu. L’Adhérent(e) ne pourra plus bénéficier des services résultant
                                de son abonnement, jusqu’à régularisation de sa situation. Dans le cas où la régularisation de
                                l’impayé n’a pas été effectuée par l’Adhérent(e) dans un délai de deux (2) mois, le Salon
                                Studio Barber se réserve le droit, après une mise en demeure restée infructueuse, de procéder
                                à une résiliation unilatérale de l’abonnement.
                                En cas de retard de paiement, le Salon Studio Barber se réserve le droit de suspendre l'accès
                                au rendez-vous, jusqu'à régularisation de la situation.
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">7. Droit de Rétractation</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Conformément à l'article L221-18 du Code de la consommation, l’Adhérent(e) dispose d'un
                                délai de quatorze (14) jours à compter de la date de souscription de l'abonnement pour
                                exercer son droit de rétractation, sans avoir à justifier de motifs ni à payer de pénalités.
                                L’Adhérent(e) peut exercer cette faculté via son compte sur l’application Salon Studio Barber
                                en cliquant sur la rubrique « Résilier mon abonnement » ou en adressant une lettre
                                recommandée avec accusé de réception à l’adresse suivante;
                                1 Rue Aubert Dubayet, 38000 Grenoble - Studio Barber, Grenoble, France.
                                La prise en compte de la résiliation sera effective à compter du jour de la réception de la lettre
                                recommandée avec accusé de réception
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">8. Résiliation de l'Abonnement</SectionTitle>
                            <SectionTitle variant="h6">8.1. Résiliation par l’Adhérent(e)
                            </SectionTitle>
                            <Typography variant="body1" paragraph>
                                L’Adhérent(e) peut résilier son abonnement à l'issue de la période d’engagement initiale de
                                douze (12) mois, en respectant un préavis de deux (2) mois.
                                La demande de résiliation doit être effectuée à partir de son compte sur l’application Salon
                                Studio Barber en cliquant sur la rubrique « Résilier mon abonnement » ou en adressant une
                                lettre recommandée avec accusé de réception à l’adresse suivante ;
                                1 Rue Aubert Dubayet, 38000 Grenoble - Studio Barber, Grenoble, France.
                                La résiliation prendra effet à l’issue de la fin de la période de préavis de deux (2) mois.
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h6">8.2. Résiliation par le Salon Studio Barber</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Sans préjudice des dommages et intérêts que le Salon Studio Barber pourrait solliciter, ce
                                dernier se réserve le droit de procéder à la résiliation unilatérale et anticipée de l’abonnement
                                de l’Adhérent(e), en cas de manquement grave et répété des obligations visées par la présente
                                convention.
                                Notamment en cas de :

                                <ul>
                                    <li>Non-respect des conditions d’accès au service</li>
                                    <li>Non-respect des modalités de paiement (défaut de paiement total ou partiel répété)</li>
                                    <li>Fourniture de fausses informations lors de l’inscription au Service Salon Studio Barber</li>
                                    <li>Contournement ou tentative de contournement des mesures techniques de protection mises en place par le Salon Studio Barber</li>
                                    <li>Partage du titre d’abonnement à un tiers</li>
                                    <li>Agissements contraires aux intérêts commerciaux du Salon Studio Barber</li>
                                    <li>Violation de la législation applicable</li>
                                </ul>

                                En outre, l’abonnement sera résilié en cas de comportement abusif de l’Adhérent(e) envers autrui ou les membres de l’équipe du Salon Studio Barber, notamment, pour propos injurieux, irrespectueux, discriminatoires ou tout autre usage d’un langage inapproprié. Il en va de même en cas, d’atteinte à l’intégrité physique de toute personne et/ou aux biens présents dans l’établissement Salon Studio Barber, d’un comportement causant un trouble à l’ordre public et au bon fonctionnement du Salon Studio Barber.
                                La résiliation de l’abonnement prendra effet dès sa notification à l’Adhérent(e) par lettre recommandée avec accusé de réception.
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">10. Responsabilitée</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Le Salon Studio Barber s'engage à fournir les prestations incluses dans l'abonnement avec
                                diligence et professionnalisme.
                                Toutefois, le Salon Studio Barber ne saurait être tenu responsable en cas d'indisponibilité
                                temporaire des services pour des raisons de maintenance ou de force majeure.
                                La responsabilité de l’Editeur du site internet et de l’application, ne peut être engagée en cas
                                de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l’accès au Site
                                ou à une de ses fonctionnalités. Le matériel de connexion au site internet utilisé est sous
                                l’entière responsabilité de l'Adhérent(e) à qui il appartiendra de prendre toutes les mesures
                                appropriées pour protéger son matériel et ses propres données notamment d’attaques virales
                                par Internet. L’Editeur ne pourra être tenu responsable en cas de poursuites judiciaires à
                                l’encontre de l’utilisateur du fait de l’usage du site ou de tout service accessible via Internet,
                                de même qu’en raison du non-respect par l’Adhérent(e) des conditions générales du Salon
                                Studio Barber. L’Editeur décline toute responsabilité quant au contenu des sites vers lesquels
                                conduisent les éventuels liens mis en place depuis le site internet ou l’application Salon
                                Studio Barber. L’Adhérent(e) déclare être informé des risques particuliers liés au
                                fonctionnement d’Internet, et notamment du fait que les informations qui y transitent ou y
                                sont stockées peuvent être récupérées, transférées et/ou altérées
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">11. Données Personnelles          </SectionTitle>
                            <Typography variant="body1" paragraph>
                                L’Adhérent(e) se voit remettre, préalablement à la signature de son abonnement, la Politique
                                de Protection de ses Données Personnelles, qui expose les modalités et caractéristiques des
                                traitements de ses données mis en œuvre par le Salon Studio Barber et informe l’Adhérent(e)
                                sur les droits qui lui sont conférés. La présente Politique de confidentialité s’applique
                                indifféremment aux données collectées par le Salon Studio Barber, par le biais de
                                l’application et/ou via son site internet. Le traitement informatique du dossier de
                                l'Adhérent(e) dans le cadre de la Loi Informatique et Libertés du 6 janvier 1978 modifiée
                                en 2004 et du Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril
                                2016 applicable à compter du 25 mai 2018, lui ouvre notamment un droit d’accès, de
                                rectification, et d’opposition aux données de son dossier. Pour user de cette faculté,
                                l’Adhérent(e) doit s’adresser au Salon Studio Barber ou par courrier à l’adresse suivante, 1
                                Rue Aubert Dubayet, 38000 Grenoble - Studio Barber, Grenoble, France, en précisant sa
                                référence client. L'Adhérent(e) peut également introduire une réclamation auprès des
                                autorités de contrôle et notamment de la CNIL (https://www.cnil.fr/fr/plaintes)
                            </Typography>
                        </Box>


                        <Box>
                            <SectionTitle variant="h5">11. Loi Applicable et Juridiction Compétente</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Les présentes conditions générales d’utilisation et de vente sont soumises au droit français.
                                Toutes réclamations relatives à l’application des présentes conditions générales d’utilisation
                                et de vente doivent être destinées au Salon Studio Barber, soit par l’intermédiaire de
                                l’application et/ou le site internet, ou bien par courrier postal à l’adresse suivante ;
                                1 Rue Aubert Dubayet, 38000 Grenoble - Studio Barber, Grenoble, France.
                                En cas d’échec de la réclamation soumise dans les conditions ci-dessus décrites et
                                conformément aux dispositions du Code de la consommation, l’Adhérent(e) a le droit de
                                recourir gratuitement au service de médiation de son choix. En cas de persistance d’un litige
                                entre le Salon Studio Barber et l’Adhérent(e), les parties s'engagent à rechercher une solution
                                amiable avant de saisir la juridiction compétente.          </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">12. Nullité d’une clause </SectionTitle>
                            <Typography variant="body1" paragraph>
                                Dans l’hypothèse où l’une des dispositions des présentes conditions générales d’utilisation et
                                de vente serait déclarée invalide ou inopposable pour quelque cause que ce soit, les autres
                                dispositions demeureront applicables sans changement, sauf les cas où le contrat ne pourrait
                                subsister sans elle.
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">13. Cas de force majeur</SectionTitle>
                            <Typography variant="body1" paragraph>
                                Les obligations résultant de cette convention peuvent être suspendues temporairement en cas
                                d’événement rendant impossibles leurs bonnes exécutions.
                                En cas de force majeure, c’est-à-dire en présence d’un événement imprévisible, irrésistible et
                                extérieur à la volonté des parties, notamment, sans que cette liste soit limitative, pour des
                                raisons de santé, de décès, ou pour des causes professionnelles, l'Adhérent(e) peut demander
                                la résiliation du contrat par courrier recommandé avec avis de réception, adressé au Salon
                                Studio Barber.
                                La résiliation prendra effet à l’issue d’un délai de préavis d’un (1) mois, qui commencera à
                                courir dès réception de la demande de résiliation. Dans le cas où le Salon Studio Barber a
                                d’ores et déjà perçu le prix de l’abonnement pour l’année en cours, ce dernier, remboursera
                                l’abonnement à l’Adhérent(e) au prorata temporis.
                            </Typography>
                        </Box>

                        <Box>
                            <SectionTitle variant="h5">14. Acceptation des Conditions Générales</SectionTitle>
                            <Typography variant="body1" paragraph>
                                La souscription à l'abonnement implique l'acceptation sans réserve des présentes conditions
                                générales par l’Adhérent(e). Le Salon Studio Barber se réserve le droit de modifier, à sa
                                discrétion, les présentes conditions générales d’utilisation et de vente. Le Salon Studio Barber
                                informera l’Adhérent(e) par courrier électronique à l’adresse renseignée sur son profil de
                                toute modification des présentes, au moins un (1) mois avant leur prise d’effet. Dans
                                l’hypothèse où l’Adhérent(e) n’accepterait pas ces modifications, ce dernier sera libre de
                                résilier son abonnement dans les conditions visées à l’article 8 de la présente convention. À
                                défaut de résiliation, les nouvelles conditions générales d’utilisation et de vente seront
                                applicables un (1) mois après la réception de la notification de modification.
                            </Typography>
                        </Box>

                        <Divider sx={{ my: 4 }} />
                        <Typography variant="body2" align="center">
                            Pour toute information complémentaire, vous pouvez nous contacter à :<br />
                            Adresse : 1 Rue Aubert Dubayet, 38000 Grenoble<br />
                            Téléphone : 09 70 99 02 72<br />
                            Courriel : studiobarber.fr@gmail.com<br />
                            Ces conditions générales sont disponibles au sein de nos établissements et sur notre
                            site internet.
                        </Typography>
                    </Container>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onAccept}
                    color="primary"
                    disabled={!hasScrolledToBottom}
                >
                    J'accepte
                </Button>
                <Button onClick={onClose} color="secondary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}

