import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import background from "../theme/ressources/background.jpg";
import theme from '../theme/ThemePerso';
import { ThemeProvider } from '@mui/material/styles';
import logo from '../theme/ressources/studioBarberlogoNew.png';
import BackButton from "../composant/BackButton";

const SignInContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', // Centré verticalement
  width: '100%', // Prend toute la largeur disponible
  maxWidth: '25rem', // Limite maximale (équivaut à 400px)
  margin: '0 auto', // Centré horizontalement
  padding: '2rem', // Espaces intérieurs
  minHeight: '100%',
}));

export default function SignIn(props) {
  const [usernameError, setUsernameError] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const isValid = validateInputs();
    if (!isValid) {
      return;
    }

    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');

    try {
      const response = await fetch(`/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Erreur lors de la connexion:', errorData);
        alert('Erreur lors de la connexion. Veuillez vérifier vos informations.');
      } else {
        const result = await response.json();
        localStorage.setItem('token', result.entity.access_token);
        navigate("/userapp");
      }
    } catch (error) {
      console.error('Erreur réseau ou serveur:', error);
      alert('Erreur de connexion. Veuillez réessayer plus tard.');
    }
  };

  const validateInputs = () => {
    const username = document.getElementById('username');
    const password = document.getElementById('password');

    let isValid = true;

    if (!username.value || username.value.length < 1) {
      setUsernameError(true);
      setUsernameErrorMessage('Veuillez entrez un d\'utilisateur.');
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Le mot de passe doit contenir au moins 10 caractères.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
      }}
    >

      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderBottomLeftRadius: "50%",
          borderBottomRightRadius: "50%",
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px", 
            zIndex: 10,
          }}
        >
          <BackButton
            variant="contained"
            sx={{
              backgroundColor: "#f760f9",
              color: "#fff",
              '&:hover': {
                backgroundColor: "#f760f9",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Prend toute la hauteur du conteneur
          }}
        >
          <img src={logo} alt="logo" style={{ width: "20rem", height: "auto" }} />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "30rem",
          paddingRight: "5rem",
          boxSizing: "border-box",
        }}
      >
        <ThemeProvider theme={theme}>
          <SignInContainer direction="column" justifyContent="space-between">
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: "1rem" }}>
              <Typography
                variant="h4"
                sx={{ textAlign: "left", fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 500 }}
              >
                Mot de passe oublié ?
              </Typography>
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormControl>
                <TextField
                  error={usernameError}
                  helperText={usernameErrorMessage}
                  id="username"
                  type="username"
                  name="username"
                  placeholder="Nom d'utilisateur"
                  autoComplete="username"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  color={usernameError ? 'error' : 'primary'}
                  sx={{ ariaLabel: 'username' }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  error={passwordError}
                  helperText={passwordErrorMessage}
                  name="password"
                  placeholder="Mot de passe"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                  color={passwordError ? 'error' : 'primary'}
                />
              </FormControl>
              <Link
                type="button"
                href="/passwordReset"
                variant="body2"
                underline='hover'
                sx={{
                  alignSelf: 'baseline',
                  color: '#000000',         // Couleur du texte (bleu par exemple)
                  fontSize: "0.8rem",
                  fontFamily: 'Arial, sans-serif',
                }}
              >
                Mot de passe oublié?
              </Link>
              <Box textAlign='center'>
                <Button
                  type="submit"
                  fullWidth
                  alignItems="center"
                  variant="contained"
                  onClick={validateInputs}
                  sx={{
                    marginTop: "5rem",
                    width: "15rem",
                    backgroundColor: '#f760f9',
                    color: '#ffffff',
                    '&:hover': {
                      backgroundColor: '#f760f9',
                    },
                  }}
                >
                  Se connecter
                </Button>
              </Box>
              <Typography sx={{
                textAlign: 'center',
                color: '#000000',
                fontSize: "0.8rem",
                fontFamily: 'Arial, sans-serif',
              }}>
                Pas encore de compte?<br />
                <span>
                  <Link
                    href="/signup"
                    underline='hover'
                    variant="body2"
                    sx={{
                      alignSelf: 'center', color: '#000000',
                      fontSize: "1rem", fontWeight: "600"
                    }}
                  >
                    Inscrivez-vous
                  </Link>
                </span>
              </Typography>
            </Box>
          </SignInContainer>
        </ThemeProvider>
      </Box>
    </Box>
  );
}