import React, { useState } from "react";
import MuiCard from '@mui/material/Card';
import AppTheme from '../theme/AppTheme';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../theme/logo/studioBarberLogo.png';
import QrScanner from 'react-qr-scanner';
import { API_BASE_URL } from '../helper/config';
import Button from '@mui/material/Button';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: '8rem',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const QRContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

function QRScan(props) {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [showScanner, setShowScanner] = useState(true);

  const handleOnClickScanner = () => {
    setUserInfo(null)
    setShowScanner(true);
  };

  const handleScan = async (result) => {
    if (result) {
      const scannedCode = result.text;
      setShowScanner(false);
      console.log(scannedCode);

      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${API_BASE_URL}/api/qrcode/get-user-infos-qr-code?userId=${scannedCode}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données.');
        }

        const result = await response.json();

        setUserInfo({
          imageBase64: result.entity.profilePicture,
          firstName: result.entity.firstName,
          lastName: result.entity.lastName,
          subscriptionState: result.entity.isActif
          
        });
        setError(null);
      } catch (err) {
        console.error(err);
        setError("Impossible de récupérer les informations. Veuillez réessayer.");
        setUserInfo(null);
      }
    }
  };

  const handleError = (error) => {
    console.error(error);
    setError("Erreur avec le scanner de QR Code.");
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <QRContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography
              component="h1"
              variant="h4"
              sx={{ fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
            >
              QR Code
            </Typography>
            <img src={logo} alt="logo" width='100rem' height='50rem' />
          </Box>
          <div>
            <Typography variant="h6">Scanner de QR Code</Typography>
            {showScanner ?
              (
                <QrScanner  
                  facingmode={"environment"}
                  constraints={{
                    video: { facingMode: "environment" }
                  }}
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ width: '100%' }}
                />
              )
              :
              (
                <Button variant="contained" onClick={handleOnClickScanner}> Scanner à nouveau </Button>
              )

            }
            {error && (
              <Typography color="error">{error}</Typography>
            )}
            {userInfo && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Informations utilisateur</Typography>
                <img
                  src={`data:image/png;base64,${userInfo.imageBase64}`}
                  alt="QR Code"
                  style={{ maxWidth: '100%', borderRadius: '8px' }}
                />
                <Typography>Nom : {userInfo.lastName}</Typography>
                <Typography>Prénom : {userInfo.firstName}</Typography>
                <Typography
                  sx={{
                    color: userInfo.subscriptionState ? 'green' : 'red',
                  }}
                >
                  Statue de l'abonnement : {userInfo.subscriptionState ? 'Actif' : 'Non actif'}
                </Typography>

              </Box>
            )}
          </div>
        </Card>
      </QRContainer>
    </AppTheme>
  );
}

export default QRScan;
