import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material'; 
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <IconButton onClick={handleBack} >
        <ArrowBackIosIcon style={{ fontSize: '3rem', color:'#ffffff' }} />
    </IconButton>
  );
};

export default BackButton;