import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, IconButton } from '@mui/material'; 
import LogoutIcon from '@mui/icons-material/Logout';


const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/signin'); 
  };

  return (
    <IconButton onClick={handleLogout} >
      <LogoutIcon style={{ fontSize: '3rem' }} />
    </IconButton>
  );};

export default LogoutButton;
