import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./vue/HomePage.js"
import SignIn from "./vue/SignIn.js"
import SignUp from "./vue/SignUp.js"
import FormulairePaiement from "./vue/FormulairePaiement.js";
import Return from "./vue/Return.js";
import Home from "./vue/Home.js";
import Abonnement from "./vue/Abonnement.js";
import GestionAbonnement from "./vue/GestionAbonnement.js";
import QRCode from "./vue/QRCode.js";
import QRScan from "./vue/QRScan.js";
import Layout from "./composant/Layout.js";
import PriseAbonnement from "./vue/PriseAbonnement.js";
import PriseAbonnementPP from "./vue/PriseAbonnementPP.js";
import ProtectedRoute from "./composant/ProtectedRoute.js";
import PublicRoute from "./composant/PublicRoute.js";
import ModificationUser from "./vue/ModificationUser.js"
import ModificationUserAdmin from "./vue/ModificationUserAdmin.js"
import PageAdmin from "./vue/PageAdmin.js";
import CGU from "./vue/CGU.js";
import HistoriqueScan from "./vue/HistoriqueScan.js";
import PassworReset from "./vue/PasswordReset.js"


function App() {
  return (
      <Router>
        <Routes>
          <Route path="/userapp" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route index element={<Home />} /> 
            <Route path="abonnement" element={<Abonnement />} />
            <Route path="qr-scan" element={<QRScan />} />
            <Route path="gestion-abonnement" element={<GestionAbonnement />} />
            <Route path="prise-abonnement" element={<PriseAbonnement />} />
            <Route path="prise-abonnement-pp" element={<PriseAbonnementPP />} />
            <Route path="checkout" element={<FormulairePaiement />} />
            <Route path="qr-code" element={<QRCode />} />
            <Route path="modification-user" element={<ModificationUser />} />
            <Route path="modification-user-admin" element={<ModificationUserAdmin />} />
            <Route path="page-admin" element={<PageAdmin />} />
            <Route path="historique-scan" element={<HistoriqueScan />} />
          </Route>
          <Route path="/" element={<PublicRoute><HomePage /></PublicRoute>} />
          <Route path="/signin" element={<PublicRoute><SignIn /></PublicRoute>} />
          <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
          <Route path="/passwordReset" element={<PublicRoute><PassworReset /></PublicRoute>} />
          <Route path="/conditions-generales" element={<PublicRoute><CGU /></PublicRoute>} />
          <Route path="/checkout" element={<ProtectedRoute><FormulairePaiement /></ProtectedRoute>} />
          <Route path="/return" element={<ProtectedRoute><Return /></ProtectedRoute>} />
        </Routes>
      </Router>
  );
}

export default App;