import { createTheme } from '@mui/material/styles';

// Création d'un thème personnalisé
const theme = createTheme({
    components: {
      // Personnalisation des TextFields
      MuiTextField: {
        styleOverrides: {
          root: {
            // Appliquer un fond gris clair
            backgroundColor: '#e9e8e8',
            borderRadius: '1rem',
          },
          // Personnalisation de l'input à l'intérieur du TextField
          input: {
            color: '#363636', // Texte gris clair dans le champ
          },
          // Personnalisation du placeholder
          placeholder: {
            color: 'black', // Placeholder en noir
          },
        },
      },
      // Personnalisation du label des TextFields (si nécessaire)
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#363636', // Couleur du label
          },
        },
      },
      // Suppression de la bordure autour du TextField
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& fieldset': {
              borderColor: 'transparent', // Pas de bordure autour du champ
              borderRadius: '1rem',
            },
            '&:hover fieldset': {
              borderColor: 'transparent', // Pas de bordure au survol
              borderRadius: '1rem',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'transparent', // Pas de bordure lorsqu'il est focus
              borderRadius: '1rem',
            },
          },
        },
      },
    },
  });

export default theme;