import React, { useState, useEffect } from "react";
import { Typography, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, TablePagination } from '@mui/material';
import { API_BASE_URL } from '../helper/config';
import { format } from 'date-fns';
import background from "../theme/ressources/background.jpg";

function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return format(date, 'dd/MM/yyyy HH:mm:ss');
}

export default function HistoriqueScan() {
    const [historyData, setHistoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);


    const fetchSubscriptions = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(
                `${API_BASE_URL}/api/qrcode/get-all-history-scan?pageNumber=${page}&pageSize=${rowsPerPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "ngrok-skip-browser-warning": "69420",
                },
            }
            );

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }

            const data = await response.json();
            setHistoryData(data.entity.content);
            setTotalItems(data.entity.totalElements);
        } catch (err) {
            console.error(err.message)
            setError("Historique des scans indisponible");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchSubscriptions();
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "100%", backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", height: "150px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", position: "relative", top: 0 }}>
                <Typography variant="h5" sx={{ color: "#dfdfdf", fontWeight: "bold", fontFamily: "sans-serif", textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)" }}>
                    Historique des scans
                </Typography>
            </Box>
            <Box sx={{ width: "90%", marginTop: "5rem", }}>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow sx={{ backgroundColor: "#f760f9" }}>
                                    <TableCell sx={{color: "white", fontWeight: "bold",}} >Date</TableCell>
                                    <TableCell sx={{color: "white", fontWeight: "bold",}} >Nom d'utilisateur</TableCell>
                                    <TableCell sx={{color: "white", fontWeight: "bold",}} >Scanneur</TableCell>
                                    <TableCell sx={{color: "white", fontWeight: "bold",}} >Etat de l'abonnement</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: "#fbeffb" }}>
                                {historyData.map((historyData, index) => (
                                    <TableRow key={index}>
                                        <TableCell sx={{color: "#474646", fontWeight: "bold",}}>{formatTimestamp(historyData.dateScanned)}</TableCell>
                                        <TableCell sx={{color: "#474646", fontWeight: "bold",}}>{historyData.usernameScanned}</TableCell>
                                        <TableCell sx={{color: "#474646", fontWeight: "bold",}}>{historyData.usernameWhosScan}</TableCell>
                                        <TableCell sx={{color: "#474646", fontWeight: "bold",}}>{historyData.etatAbonnement}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{ backgroundColor: "#f5f2f5" }}
                   />
                </Paper>
            </Box>
        </div>
    );
}
