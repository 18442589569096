import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Modal,
    Typography,
    TextField,
    FormControl
} from '@mui/material';
import { API_BASE_URL } from '../helper/config';
import { styled } from '@mui/material/styles';
import background from "../theme/ressources/background.jpg";
import MailLockIcon from '@mui/icons-material/MailLock';
import Stack from '@mui/material/Stack';
import BackButton from "../composant/BackButton";


const ContainerResetPassword = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '25rem',
    margin: '0 auto',
    padding: '2rem',
    minHeight: '100%',
}));

function PasswordReset() {
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    const validateInputs = () => {
        const email = document.getElementById('email');
        let isValid = true;
        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Veuillez entrer un email valide.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }
        return isValid;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateInputs()) {
            return;
        }

        const email = document.getElementById('email').value;

        try {
            const response = await fetch(`${API_BASE_URL}/mot-de-passe-oublie?userEmail=${email}`, {
                method: 'GET',
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.status === "401") {
                    throw new Error('Une erreur est survenue, erreur:', errorData.status);
                }
                throw new Error(errorData.status || 'Une erreur est survenue.');
            }

            setOpenModal(true); // Ouvre la modale en cas de succès

        } catch (error) {
            console.error('Erreur lors de la soumission :', error);
            alert(`Erreur : ${error.message}`);
        }
    };

    const handleNavigateHome = () => {
        setOpenModal(false);
        navigate('/'); // Redirige vers la page d'accueil
    };

    return (
        <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box sx={{ width: "100%", backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", position: "relative", top: 0 }}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        zIndex: 10,
                    }}
                >
                    <BackButton
                        variant="contained"
                        sx={{
                            backgroundColor: "#f760f9",
                            color: "#fff",
                            '&:hover': {
                                backgroundColor: "#f760f9",
                            },
                        }}
                    />
                    </Box>
                    <MailLockIcon sx={{ fontSize: '8rem', color: 'gray' }} />
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "30rem",
                        paddingRight: "5rem",
                        boxSizing: "border-box",
                    }}
                >
                    <ContainerResetPassword direction="column" justifyContent="space-between">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column', // Disposition verticale
                            alignItems: 'center',    // Centre les éléments horizontalement
                            justifyContent: 'center', // Centre les éléments verticalement
                            width: '100%',
                            textAlign: 'center',
                            marginBottom: "1rem"
                        }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    fontWeight: 500,
                                    marginBottom: "1rem" // Espacement entre le titre et le texte
                                }}
                            >
                                Mot de passe oublié ?
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontFamily: 'Arial, Helvetica, sans-serif',
                                    fontWeight: 500
                                }}
                            >
                                Renseigne ton adresse-mail, nous t’enverrons un mail
                                pour réinitialiser ton mot de passe.
                            </Typography>
                        </Box>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            noValidate
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                maxWidth: "30rem",
                                paddingRight: "2rem",
                                paddingTop: "2rem",
                                paddingLeft: "2rem",
                                boxSizing: "border-box",
                                gap: 2,
                            }}
                        >
                            <FormControl>
                                <TextField
                                    error={emailError}
                                    helperText={emailErrorMessage}
                                    id="email"
                                    type="email"
                                    name="email"
                                    placeholder="email"
                                    autoComplete="email"
                                    autoFocus
                                    required
                                    fullWidth
                                    variant="outlined"
                                    color={emailError ? 'error' : 'primary'}
                                    sx={{ ariaLabel: 'email' }}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                onClick={validateInputs}
                                sx={{
                                    backgroundColor: '#f760f9',
                                    color: '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#f760f9',
                                    },
                                }}
                            >
                                Confirmer
                            </Button>
                        </Box>

                        <Modal
                            open={openModal}
                            onClose={() => setOpenModal(false)}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    bgcolor: 'background.paper',
                                    borderRadius: '8px',
                                    boxShadow: 24,
                                    p: 4,
                                    textAlign: 'center',
                                    width: '80%',
                                    maxWidth: '400px',
                                }}
                            >
                                <Typography id="modal-title" variant="h6" component="h2">
                                    Réinitialisation envoyée !
                                </Typography>
                                <Typography id="modal-description" sx={{ mt: 2 }}>
                                    Un email de réinitialisation a été envoyé, veuillez vérifier votre boîte de réception.
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{ mt: 3 }}
                                    onClick={handleNavigateHome}
                                >
                                    Retourner à l'accueil
                                </Button>
                            </Box>
                        </Modal>
                    </ContainerResetPassword>
                </Box>
        </div>
    );
}

export default PasswordReset;
