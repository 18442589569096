import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import background from "../theme/ressources/background.jpg";
import LogoutButton from "../composant/LogoutButton";
import { API_BASE_URL } from '../helper/config';

function ModificationUserAdmin() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalUsers, setTotalUsers] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    firstName: "",
    email: "",
    phone: "",
    role: "user",
    profilePicture: "",
    idKeycloak: "",
  });
  const [capturedImage, setCapturedImage] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'username', direction: 'asc' });  // Ajouter l'état pour le tri
  const [searchTerm, setSearchTerm] = useState(""); // Valeur du champ de recherche
  const [updatedProfilePhoto, setUpdatedProfilePhoto] = useState(false);
  const [selectedUserToSuspend, setSelectedUserToSuspend] = useState(null); // Utilisateur à suspendre
  const [openSuspendDialog, setOpenSuspendDialog] = useState(false);

  const webcamRef = React.useRef(null); // Référence pour la webcam


  const handleSuspendClick = (user) => {
    setSelectedUserToSuspend(user);
    setOpenSuspendDialog(true);
  };

  // Fonction pour fermer le dialogue
  const handleCloseSuspendDialog = () => {
    setSelectedUserToSuspend(null);
    setOpenSuspendDialog(false);
  };

  const handleConfirmSuspend = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/api/user/suspend`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: selectedUserToSuspend.id }),
      });
      if (response.ok) {
        alert("Utilisateur suspendu avec succès !");
        const data = await response.json();
        setUsers(data.entity.content);
        setTotalUsers(data.entity.totalElements);
      } else {
        alert("Erreur lors de la suspension de l'utilisateur.");
      }
    } catch (error) {
      console.error("Erreur lors de la suspension :", error);
      alert("Erreur lors de la suspension de l'utilisateur.");
    } finally {
      handleCloseSuspendDialog();
    }
  };

  // Fonction pour récupérer les utilisateurs avec les paramètres de tri
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${API_BASE_URL}/api/user/get-all-users?pageNumber=${page}&pageSize=${rowsPerPage}&sortColumn=${sortConfig.key}&sortDirection=${sortConfig.direction}&username=${searchTerm}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            "ngrok-skip-browser-warning": "69420",
          },
        });
        const data = await response.json();
        setUsers(data.entity.content);
        setTotalUsers(data.entity.totalElements);
      } catch (error) {
        console.error("Erreur lors du chargement des utilisateurs:", error);
      }
    };

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeoutId = setTimeout(() => {
      fetchUsers();
    }, 500);

    setDebounceTimeout(timeoutId);
  }, [page, rowsPerPage, sortConfig, searchTerm, openModal]);  // Ajouter sortConfig dans la liste des dépendances

  const handleChangeProfilePicture = () => {
    setOpenCamera(true);
  };

  // Capture la photo depuis la webcam
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc); // Stocke l'image capturée
  };

  // Reprendre la photo
  const retakePhoto = () => {
    setCapturedImage(null); // Réinitialise la photo capturée
  };

  // Confirmer la photo
  const confirmPhoto = () => {
    if (capturedImage) {
      const byteCharacters = atob(capturedImage.split(',')[1]);
      const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
      const byteArray = new Uint8Array(byteNumbers);
      setUserData((prevData) => ({
        ...prevData,
        profilePicture: Array.from(byteArray),
      }));
      setUpdatedProfilePhoto(true);
    }
    setOpenCamera(false);
  };

  // Gestion de la page suivante/précédente
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Gestion du nombre de lignes par page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Gérer la saisie dans le champ de recherche
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Réinitialiser à la première page après un changement de recherche
  };

  // Sélectionner un utilisateur et ouvrir le modal
  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setUserData({
      lastName: user.lastName,
      firstName: user.firstName || "",
      email: user.email,
      phone: user.phone || "",
      role: user.role,
      profilePicture: user.profilePicture,
      idKeycloak: user.idKeycloak
    });
    setOpenModal(true);
  };

  // Fermer le modal
  const handleCloseModal = () => {
    setUpdatedProfilePhoto(false);
    setCapturedImage(false);
    setOpenModal(false);
  };

  // Sauvegarder les informations modifiées de l'utilisateur
  const handleSave = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${API_BASE_URL}/api/user/change-user-info`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      if (response.ok) {
        alert("Utilisateur mis à jour avec succès !");
        setUpdatedProfilePhoto(false);
        setCapturedImage(false);
        setOpenModal(false);
      } else {
        alert("Erreur lors de la mise à jour de l'utilisateur.");
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
      alert("Erreur lors de la mise à jour de l'utilisateur.");
    }
  };

  // Fonction de tri (changer de colonne ou d'ordre de tri)
  const handleSort = (columnKey) => {
    let direction = 'asc';  // Si tri ascendant par défaut
    if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
      direction = 'desc';  // Inverser le sens du tri si la même colonne est cliquée
    }

    setSortConfig({ key: columnKey, direction });  // Mettre à jour le config de tri
  };

  return (
    <div style={{ backgroundColor: "#f5f5f5", minHeight: "100vh", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box sx={{ width: "100%", backgroundImage: `url(${background})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", height: "150px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", position: "relative", top: 0 }}>
        <Box sx={{ position: "absolute", top: "10px", right: "10px" }}>
          <LogoutButton />
        </Box>
        <Typography variant="h5" sx={{ color: "#dfdfdf", fontWeight: "bold", fontFamily: "sans-serif", textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)" }}>
          Liste des utilisateurs
        </Typography>
      </Box>

      {/* Champ de recherche */}
      <Box sx={{ width: "80%", marginTop: "5rem", }}>
        <TextField
          label="Rechercher un utilisateur"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ marginBottom: 2 }}
        />
      </Box>

      <Box sx={{ width: "90%", marginTop: "5rem", }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f760f9" }}>
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: sortConfig.key === 'username' ? '#ffeb3b' : 'white', // Change color if sorted
                    }}
                    onClick={() => handleSort('username')}
                  >
                    Nom d'utilisateur
                    {sortConfig.key === 'username' && (
                      sortConfig.direction === 'asc' ? <ArrowUpward sx={{ fontSize: 18, color: 'white' }} /> : <ArrowDownward sx={{ fontSize: 18, color: 'white' }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: sortConfig.key === 'lastName' ? '#ffeb3b' : 'white', // Change color if sorted
                    }}
                    onClick={() => handleSort('lastName')}
                  >
                    Nom
                    {sortConfig.key === 'lastName' && (
                      sortConfig.direction === 'asc' ? <ArrowUpward sx={{ fontSize: 18, color: 'white' }} /> : <ArrowDownward sx={{ fontSize: 18, color: 'white' }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: sortConfig.key === 'firstName' ? '#ffeb3b' : 'white', // Change color if sorted
                    }}
                    onClick={() => handleSort('firstName')}
                  >
                    Prénom
                    {sortConfig.key === 'firstName' && (
                      sortConfig.direction === 'asc' ? <ArrowUpward sx={{ fontSize: 18, color: 'white' }} /> : <ArrowDownward sx={{ fontSize: 18, color: 'white' }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: sortConfig.key === 'phone' ? '#ffeb3b' : 'white', // Change color if sorted
                    }}
                    onClick={() => handleSort('phone')}
                  >
                    Téléphone
                    {sortConfig.key === 'phone' && (
                      sortConfig.direction === 'asc' ? <ArrowUpward sx={{ fontSize: 18, color: 'white' }} /> : <ArrowDownward sx={{ fontSize: 18, color: 'white' }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: sortConfig.key === 'email' ? '#ffeb3b' : 'white', // Change color if sorted
                    }}
                    onClick={() => handleSort('email')}
                  >
                    Email
                    {sortConfig.key === 'email' && (
                      sortConfig.direction === 'asc' ? <ArrowUpward sx={{ fontSize: 18, color: 'white' }} /> : <ArrowDownward sx={{ fontSize: 18, color: 'white' }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      color: sortConfig.key === 'role' ? '#ffeb3b' : 'white', // Change color if sorted
                    }}
                    onClick={() => handleSort('role')}
                  >
                    Rôle
                    {sortConfig.key === 'role' && (
                      sortConfig.direction === 'asc' ? <ArrowUpward sx={{ fontSize: 18, color: 'white' }} /> : <ArrowDownward sx={{ fontSize: 18, color: 'white' }} />
                    )}
                  </TableCell>
                  <TableCell sx={{ color: "white", fontWeight: "bold" }}>Suspendre</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "#fbeffb" }}>
                {users.map((user) => (
                  <TableRow key={user.id} onClick={() => handleUserSelect(user)}>
                    <TableCell sx={{ color: "#474646", fontWeight: "bold", }}>{user.username}</TableCell>
                    <TableCell sx={{ color: "#474646", fontWeight: "bold", }}>{user.lastName}</TableCell>
                    <TableCell sx={{ color: "#474646", fontWeight: "bold", }}>{user.firstName}</TableCell>
                    <TableCell sx={{ color: "#474646", fontWeight: "bold", }}>{user.phone}</TableCell>
                    <TableCell sx={{ color: "#474646", fontWeight: "bold", }}>{user.email}</TableCell>
                    <TableCell>
                      <Typography>{user.role === "ROLE_ADMIN" ? "Admin" : user.role === "ROLE_STAFF" ? "Staff" : "Utilisateur"}</Typography>
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="warning" onClick={() => handleSuspendClick(user)}>
                        Suspendre
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalUsers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ backgroundColor: "#f5f2f5" }}
          />
        </Paper>
      </Box>

      {/* Modal de modification */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Modifier l'utilisateur</DialogTitle>
        <DialogContent>
          <TextField label="Nom" fullWidth value={userData.lastName} onChange={(e) => setUserData({ ...userData, lastName: e.target.value })} margin="normal" />
          <TextField label="Prénom" fullWidth value={userData.firstName} onChange={(e) => setUserData({ ...userData, firstName: e.target.value })} margin="normal" />
          <TextField label="Email" fullWidth value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} margin="normal" />
          <TextField label="Numéro de téléphone" fullWidth value={userData.phone} onChange={(e) => setUserData({ ...userData, phone: e.target.value })} margin="normal" />
          <FormControl fullWidth margin="normal">
            <InputLabel>Rôle</InputLabel>
            <Select value={userData.role} onChange={(e) => setUserData({ ...userData, role: e.target.value })}>
              <MenuItem value="ROLE_ADMIN">Admin</MenuItem>
              <MenuItem value="ROLE_USER">Utilisateur</MenuItem>
              <MenuItem value="ROLE_STAFF">Staff</MenuItem>
            </Select>
          </FormControl>

          {/* Section pour la caméra */}
          <Box sx={{ marginTop: 2 }}>
            {userData.profilePicture ? (
              <Box sx={{ marginTop: 2 }}>
                <Typography>Photo de Profile :</Typography>
                {updatedProfilePhoto === true ?
                  (<img src={capturedImage} alt="Captured" width="50%" />) :
                  (<img src={`data:image/png;base64,${userData.profilePicture}`} alt="Captured" width="50%" />)
                }

              </Box>
            ) : (
              <Typography>Aucune photo de profil</Typography>
            )}
            <Button onClick={handleChangeProfilePicture} sx={{ marginTop: 2 }}>
              Changer la photo
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">Annuler</Button>
          <Button onClick={handleSave} color="primary">Sauvegarder</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour la caméra */}
      <Dialog open={openCamera} onClose={() => setOpenCamera(false)}>
        <DialogTitle>Prendre une photo</DialogTitle>
        <DialogContent>
          {capturedImage ? (
            <img src={capturedImage} alt="Aperçu" style={{ width: '100%' }} />
          ) : (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="100%"
              videoConstraints={{ facingMode: 'user' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {capturedImage ? (
            <>
              <Button onClick={retakePhoto} color="secondary">Reprendre</Button>
              <Button onClick={confirmPhoto} color="primary">Confirmer</Button>
            </>
          ) : (
            <Button onClick={capture} color="primary">Capturer</Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Dialogue de suspension */}
      <Dialog open={openSuspendDialog} onClose={handleCloseSuspendDialog}>
        <DialogTitle>Confirmer la suspension</DialogTitle>
        <DialogContent>
          <Typography>
            Confirmez-vous la suspension de l'utilisateur{" "}
            <strong>{selectedUserToSuspend?.username}</strong> ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuspendDialog} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleConfirmSuspend} color="primary">
            Suspendre
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModificationUserAdmin;