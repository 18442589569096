import React from "react";
import { Typography, Button, Box } from "@mui/material";
import LogoutButton from "../composant/LogoutButton";
import { Link } from "react-router-dom";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SettingsIcon from "@mui/icons-material/Settings";
import background from "../theme/ressources/background.jpg";
import { jwtDecode } from "jwt-decode";

function Home() {
  const decodedToken = jwtDecode(localStorage.getItem('token'));
  const userName = decodedToken?.preferred_username ?? "";
  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "50%",
          borderBottomRightRadius: "50%",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          top: 0,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#dfdfdf",
            fontWeight: "bold",
            fontFamily: "sans-serif",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
          }}
        >
          Bonjour {userName} !
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "5rem",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
        }}
      >
        <Button
          href="https://www.fresha.com/fr/book-now/studio-barber-ln8z38l9/all-offer?share&pId=890081"
          target="_blank"
          style={{
            backgroundColor: "#f5b2f6",
            color: "black",
            display: "flex",
            fontWeight: "bold",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            borderRadius: "8px",
            width: "15rem",
            height: "10rem",
            textDecoration: "none",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#FFB6C1",
            },
          }}
        >
          <EventAvailableIcon sx={{ fontSize: "4rem" }} />
          <span style={{ fontSize: "1rem", fontFamily: "sans-serif", textAlign: "center" }}>
            Prends rendez-vous
          </span>
        </Button>

        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: "#ADD8E6",
            color: "black",
            fontWeight: "bold",
            padding: "10px",
            borderRadius: "8px",
            width: "15rem",
            height: "10rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
          }}
        >
          <Link
            to="/userapp/abonnement"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SettingsIcon sx={{ fontSize: "4rem" }} />
            <span style={{ fontSize: "1rem", fontFamily: "sans-serif", textAlign: "center" }}>
              Gère ton abonnement
            </span>
          </Link>
        </Button>
      </Box>
    </div>
  );
}

export default Home;
