import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthCheck from '../helper/useAuthCheck';

const ProtectedRoute = ({ children }) => {
  useAuthCheck();
  const token = localStorage.getItem('token');
  
  if (!token) {
    return <Navigate to="/signin" />;
  }

  return children;
};

export default ProtectedRoute;
