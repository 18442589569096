import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import background from "../theme/ressources/background.jpg";

function PageAdmin() {

  const buttonStyle = {
    width: "20rem", // Largeur uniforme pour tous les boutons
    textAlign: "center",
    backgroundColor: "#f051f8",
    color: "#ffffff",
    fontWeight: "bold",
    padding: "12px 20px",
    borderRadius: "8px",
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "50%",
          borderBottomRightRadius: "50%",
          height: "250px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          position: "relative",
          top: 0,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#dfdfdf",
            fontWeight: "bold",
            fontFamily: "sans-serif",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
          }}
        >
          Gestion
        </Typography>

      </Box>
      <Box
        sx={{
          marginTop: "5rem",
          display: "flex",
          flexDirection: "column",
          gap: 3,
          alignItems: "center",
        }}
      >
        <Button
          component={Link}
          to="/userapp/qr-scan"
          variant="contained"
          color="primary"
          size="large"
          sx={buttonStyle}
        >
          Scanneur - QR code
        </Button>
        <Button
          component={Link}
          to="/userapp/modification-user-admin"
          variant="contained"
          color="primary"
          size="large"
          sx={buttonStyle}
        >
          Gestion des utilisateurs
        </Button>
        <Button
          component={Link}
          to="/userapp/historique-scan"
          variant="contained"
          color="primary"
          size="large"
          sx={buttonStyle}
        >
          Historique des scans
        </Button>
      </Box>
    </div>
  );
}

export default PageAdmin;
