import React, { useState, useRef } from "react";
import { Typography, Button, Container, Box, Dialog, DialogContent } from "@mui/material";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";

function PriseAbonnementPP() {
  const [imageSrc, setImageSrc] = useState(null);
  const [openCamera, setOpenCamera] = useState(false);
  const webcamRef = useRef(null);

  const openWebcam = () => {
    setOpenCamera(true);
    setImageSrc(null);
  };

  const capture = () => {
    const capturedImage = webcamRef.current.getScreenshot();
    setImageSrc(capturedImage);
    setOpenCamera(false);
  };

  const retakePhoto = () => {
    setImageSrc(null);
    setOpenCamera(true);
  };

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container
        maxWidth="sm"
        style={{
          textAlign: "center",
          padding: "2rem",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" gutterBottom style={{ color: "#333", fontWeight: "bold" }}>
          Prenez une photo pour associer à votre abonnement
        </Typography>
        <Typography variant="body2" style={{ color: "#555", marginBottom: "1rem" }}>
          Cette photo doit permettre de vous identifier.
        </Typography>

        {!imageSrc ? (
          <Button
            variant="contained"
            color="primary"
            onClick={openWebcam}
            style={{
              marginTop: "1rem",
              backgroundColor: "#007BFF",
              color: "#ffffff",
              fontWeight: "bold",
            }}
          >
            Ouvrir la caméra
          </Button>
        ) : (
          <div>
            <Typography variant="h6" gutterBottom>
              Validez ou reprenez votre photo
            </Typography>
            <img src={imageSrc} alt="Capture" style={{ maxWidth: "100%", borderRadius: "8px" }} />
            <Box mt={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={retakePhoto}
                style={{
                  marginRight: "1rem",
                  fontWeight: "bold",
                }}
              >
                Reprendre la photo
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#007BFF",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                <Link
                  to={{
                    pathname: "/userapp/checkout",
                    state: { photo: imageSrc }, 
                  }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Valider et passer au paiement
                </Link>
              </Button>
            </Box>
          </div>
        )}

        
        <Dialog open={openCamera} onClose={() => setOpenCamera(false)}>
          <DialogContent>
            <Webcam
              audio={false}
              height="240px"
              width="100%"
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode: "user" }}
              ref={webcamRef}
            />
            <Box mt={2} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={capture}
                style={{
                  backgroundColor: "#007BFF",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                Prendre la photo
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
}

export default PriseAbonnementPP;
