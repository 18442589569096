import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SettingsIcon from '@mui/icons-material/Settings';
import KeyIcon from '@mui/icons-material/Key';
import { jwtDecode } from "jwt-decode";

function NavBar() {
  const decodedToken = jwtDecode(localStorage.getItem('token'));
  console.log(decodedToken?.resource_access?.['barbershop-client-api']?.roles)
  const isAdmin = decodedToken?.resource_access?.['barbershop-client-api']?.roles.includes("ROLE_ADMIN") ||
    decodedToken?.resource_access?.['barbershop-client-api']?.roles.includes("ROLE_STAFF")

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: "#FFFFFF",
        padding: "10px 0",
        boxShadow: "0 -1px 5px rgba(0,0,0,0.1)",
      }}
    >
      <Link to="/userapp" style={{ textDecoration: "none" }}>
        <IconButton sx={{ color: "black" }} >
          <HomeIcon />
        </IconButton>
      </Link>

      <Link to="https://www.fresha.com/fr/book-now/studio-barber-ln8z38l9/all-offer?share&pId=890081" target="_blank" style={{ textDecoration: "none" }}>
        <IconButton sx={{ color: "black" }}>
          <EventAvailableIcon />
        </IconButton>
      </Link>

      {isAdmin &&
        <Link to="/userapp/page-admin" style={{ textDecoration: "none" }}>
          <IconButton sx={{ color: "black" }}>
            <KeyIcon />
          </IconButton>
        </Link>}

      <Link to="/userapp/abonnement" style={{ textDecoration: "none" }}>
        <IconButton sx={{ color: "black" }}>
          <SettingsIcon />
        </IconButton>
      </Link>
    </Box>
  );
}

export default NavBar;
